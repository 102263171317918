import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: 'voicewave', loadChildren: () => import('./voicewave/voicewave.module').then(m => m.VoicewaveModule), title: 'Xound AI Editor - Remove Background Noise, Natural pitch correction, Enhanced Listening Experience, etc' },
  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomeModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyModule), title: 'Privacy - We prioritize your security with local processing and zero server uploads.' },
  { path: 'roadmap', loadChildren: () => import('./roadmap/roadmap.component').then( m => m.RoadmapModule), title: 'Roadmap - See what we are working on and what is coming soon.' },
  { path: 'terms', loadChildren: () => import('./terms/terms.module').then( m => m.TermsModule), title: 'Terms - The legal stuff.' },
  { path: 'coupon', loadChildren: () => import('./coupon/coupon.module').then( m => m.CouponModule),  title: 'Coupon - Redeem a code for Xound' },
  { path: 'blog', loadChildren: () => import('./blog/blog.component').then(m => m.BlogModule), title: 'Blog - Learn about the latest in audio technology and Xound AI Editor.'},
  { path: 'blog/future-of-ai-noise-removal', loadChildren: () => import('./blog/blog1').then(m => m.Blog1Module), title: 'Future of AI Noise Removal - The future of audio technology and Xound AI Editor.'},
  { path: 'blog/ai-voice-enhancement', loadChildren: () => import('./blog/blog2').then(m => m.Blog2Module), title: 'AI Voice Enhancement - The future of audio technology and Xound AI Editor.'},
  { path: 'blog/ai-pitch-correction-audio-enhancement', loadChildren: () => import('./blog/blog3').then(m => m.Blog3Module), title: 'AI Pitch Correction - The future of audio technology and Xound AI Editor.'},
  { path: 'blog/science-of-background-noise', loadChildren: () => import('./blog/blog4').then(m => m.Blog4Module), title: 'Science of Background Noise - The future of audio technology and Xound AI Editor.'},
  { path: '**', pathMatch: 'full',  loadChildren: () => import('./pagenotfound/pagenotfound.component').then(m => m.PagenotfoundModule), title: '404 - Page Not Found'}, 

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
