import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private triggerPaymentLink = new Subject<string>();

  callPaymentLink$ = this.triggerPaymentLink.asObservable();

  triggerCreatePaymentLink(planId: string) {
    this.triggerPaymentLink.next(planId);
  }

  openStripe(planId: string) {
    //console.log(planId, environment.stripeUrlSingle);
    let url;
    switch (planId) {
      case 'single-use':
        url = environment.stripeUrlSingle;
        break;
      case 'monthly':
        url = environment.stripeUrlPro;
        break;
    }
    window.open(url, '_blank')

    
  }
}
