import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  Firestore,
  doc,
  getDoc,
} from '@angular/fire/firestore';
import { inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  firestore: Firestore = inject(Firestore);
  private initialOrderId = localStorage.getItem('orderId');
  private orderSubject = new BehaviorSubject<any | null>(null);
  public order$ = this.orderSubject.asObservable();

  constructor() {
    if (this.initialOrderId) {
      this.storeOrderId(this.initialOrderId);
    }
  }

  async setOrder(orderId: string) {
    localStorage.setItem('orderId', orderId);
    this.storeOrderId(orderId);
  }

  async storeOrderId(orderId: string) {
    const documentRef = doc(this.firestore, 'Orders', orderId);
    let documentData = await getDoc(documentRef);
    this.orderSubject.next(documentData);
  }

  getOrder(): string | null {
    return this.orderSubject.value;
  }
}
