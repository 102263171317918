import { Component, ElementRef, OnInit } from '@angular/core';
import {
  Firestore,
  collection,
  addDoc,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { inject } from '@angular/core';
import { SharedService } from 'src/services/shared.service';
import { environment } from '../../environments/environment';
import { OrderService } from 'src/app/order.service';
@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  showModalRedirect = false;
  showSuccess = false;
  firestore: Firestore = inject(Firestore);

  constructor(private elementRef: ElementRef, private sharedService: SharedService, private orderService: OrderService) {
    this.sharedService.callPaymentLink$.subscribe((planId: string) => {
      this.createPaymentLink(planId);
    });
  }

  async ngOnInit() {

  }


  public async createPaymentLink(planId: string) {
    this.showModalRedirect = true;
    const itemCollection = collection(this.firestore, 'StripeCallback');
    const newDoc = await addDoc(itemCollection, { pending: true, createdAt: new Date() });
    let priceId = 'price_1NcTLeESdyfnvvIG2myZOHo7';
    switch (planId) {
      case 'single-use':
        priceId = environment.stripeSingleUsePriceId;
        break;
      case 'monthly':
        priceId = environment.stripeMonthlyUsePriceId;
        break;
      case 'yearly':
        priceId = environment.stripeYearlyUsePriceId
        break;
    }
    const response = await fetch(
      environment.stripeCloudFunction,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ stripeCallbackId: newDoc.id, priceId: priceId }),
      }
    );
    const { url } = await response.json();
    const newWindow = window.open(url, '_blank');
      if (newWindow) {
        console.log('Window opened successfully');
    } else {
        console.log('Window was blocked');
        // @ts-ignore
        gtag('event', 'purchase-pending', {});
        location.href = url;
    }
    const documentRef = doc(this.firestore, 'StripeCallback', newDoc.id);
    const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          console.log("Document data:", docSnapshot.data());
          if (!docSnapshot.data()['pending'] && docSnapshot.data()['orderId']) {
            console.log('successfully subscribed');
            this.showModalRedirect = false;
            this.showSuccess = true;
            this.orderService.setOrder(docSnapshot.data()['orderId']);
            // Function to send an event
            // @ts-ignore
            gtag('event', 'purchase-completed', {orderId: docSnapshot.data()['orderId']});
            // @ts-ignore
            gtag('event', 'conversion_event_purchase', {orderId: docSnapshot.data()['orderId']});
          }
          // Here, you can handle the changes to the document as required
        } else {
          console.log("No such document!");
        }
      });
    
    return url;
  }
}
