import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { StripeComponent } from './stripe/stripe.component'

const CORE_EXPORTS = [StripeComponent]

@NgModule({
  declarations: CORE_EXPORTS,
  exports: CORE_EXPORTS,
  imports: [CommonModule],
})
export class CoreModule {}
