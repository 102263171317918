export const environment = {
  production: false,
  stripeUrlSingle: 'https://buy.stripe.com/test_9AQ6phgCE1O5bxmaEE',
  stripeUrlPro: 'https://buy.stripe.com/test_3csdRJ4TW9gxcBqaEF',
  stripeSingleUsePriceId: 'price_1NcTLeESdyfnvvIG2myZOHo7',
  stripeMonthlyUsePriceId: 'price_1NcWVlESdyfnvvIGdnCznl4G',
  stripeYearlyUsePriceId: 'price_1NcWWbESdyfnvvIG2unb7rGo',
  stripeCloudFunction: 'https://us-central1-xound-dev.cloudfunctions.net/createpaymentintent',
  checkFileSizeCloudFunction: 'https://us-central1-xound-dev.cloudfunctions.net/checkfilesize',
  firebase: {
    projectId: 'xound-dev',
    appId: '1:895755474948:web:b897a938ac5c1ad809bd51',
    storageBucket: 'xound-dev.appspot.com',
    apiKey: 'AIzaSyAdnLvDHl6-u7_tPhXnAqeL_yIettj28HA',
    authDomain: 'xound-dev.firebaseapp.com',
    messagingSenderId: '895755474948',
  },
};
