<div class="stripe-component">
  <!-- <button (click)="createPaymentLink()">Pay Now</button> -->
  <!-- overlay -->
  <div class="modal-angular">
    <div class="overlay" *ngIf="showModalRedirect" (click)="showModalRedirect = false"></div>

    <!-- modal Recirect -->
    <div class="modal" *ngIf="showModalRedirect">
      <button class="close" (click)="showModalRedirect = false">x</button>
      <section class="page-content active" data-hotspot-section="preloading-stripe">
        <section class="preloading-screen">
          <video id="xound-brand-animation" width="120" height="120" autoplay="" muted="" loop="">
            <source src="assets/xound-icon-animation_720x720.mp4" type="video/mp4">
          </video>
          <h3>Redirecting you to <span class="stripe-logo"><img src="assets/stripe.svg" alt=""></span>...</h3>
          <div class="box default">
            <p><i class="fa-sharp fa-solid fa-lock-keyhole"></i> Guaranteed safe &amp; secure checkout</p>
            <p class="powered-by-stripe">Powered by <span class="stripe-logo"><img src="assets/stripe.svg" alt=""></span></p>
          </div>
        </section>
      </section>
    </div>
    <div class="modal" *ngIf="showSuccess">
      <button class="close" (click)="showSuccess = false">x</button>
      <section class="page-content active" data-hotspot-section="preloading-stripe">
        <section class="preloading-screen">
          <h2>Thank you for your purchase on Xound.io</h2>
          <br/>
          You can start using your file right away.
          <br/> Also if you wish to come back later, an email with a link to process your file has been sent to you.
          <div>
          <button
              (click)="showSuccess = false"
              class="button-primary large continue-success"
            >
              Start processing your file
            </button>
          </div>
        </section>
      </section>
    </div>
  </div>
</div>
