import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from './order.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Xound.io';
  menuActive = false;

  constructor(private route: ActivatedRoute, private orderService: OrderService, private router: Router) {} // Inject the Router service

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['orderId']) {
        this.orderService.setOrder(params['orderId']);
      }
    });
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        // Wait for the changes to be applied, then scroll
        setTimeout(() => {
          const element = document.getElementById(fragment);
          if (element) {
            const rect = element.getBoundingClientRect();
            const offset = rect.top + window.scrollY - 100;  // Subtracting 100px from the position
            window.scroll({
              top: offset,
              behavior: 'smooth'
            });
          }
        }, 200);
      }
    });
  }

  onLinkClick(event: Event, fragment: string): void {
    this.menuActive = false;

    const element = document.getElementById(fragment);
    if (element) {
      event.preventDefault();
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.scrollY - 100;  // Subtracting 100px from the position
      window.scroll({
        top: offset,
        behavior: 'smooth'
      });
    }
  }

}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker(new URL('./app.worker', import.meta.url));
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}