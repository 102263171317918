<section class="main-wrapper">
  <header class="main-header">
    <a class="brand" href="#" title="Welo">Xound</a>
    <nav class="main-navigation">
      <ul class="menu-horizontal" [class.active]="menuActive">
        <li class="menu-tab">
          <a class="menu-label" href="/#features" (click)="onLinkClick($event, 'features')">Features</a>
        </li>
        <li class="menu-tab">
          <a class="menu-label" href="/#usecases" (click)="onLinkClick($event, 'usecases')">Use Cases</a>
        </li>
        <!--<li class="menu-tab">
          <a class="menu-label" href="/#whatsapp" (click)="onLinkClick($event, 'whatsapp')">WhatsApp</a>
        </li>-->
        <li class="menu-tab">
          <a class="menu-label" href="/#pricing" (click)="onLinkClick($event, 'pricing')">Pricing</a>
        </li>
        <li class="menu-tab">
          <a class="menu-label" href="/blog">Blog</a>
        </li>
        <li class="menu-tab">
          <a class="menu-label" href="/#about" (click)="onLinkClick($event, 'about')">About</a>
        </li>
        <li class="menu-tab">
          <a href="/voicewave" class="button-primary">Try free</a>
        </li>
        <!--<li class="menu-tab signup">
          <a class="button-secondary">Signup</a>
        </li>
        <li class="menu-tab login">
          <a class="button-icon"
            ><i class="fa-sharp fa-regular fa-arrow-right-to-bracket"></i
          ></a>
        </li>-->
      </ul>
      <div class="menu-toggle" (click)="menuActive = !menuActive">
        <div class="bars">
          <span></span><span></span><span></span><span></span>
        </div>
      </div>
    </nav>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <div class="container">
      <div class="chat-button-container">
        <button class="chat-button" aria-label="Live Chat" onclick="window.open('https://discord.gg/fWRkNcb5nJ', '_blank')">
          <span class="tooltip">Chat with us (Discord)</span>
          <!-- Your SVG code here -->
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 115.98 122.88" style="enable-background:new 0 0 115.98 122.88" xml:space="preserve"><g><path d="M17.2,0h59.47c4.73,0,9.03,1.93,12.15,5.05c3.12,3.12,5.05,7.42,5.05,12.15v38.36c0,4.73-1.93,9.03-5.05,12.15 c-3.12,3.12-7.42,5.05-12.15,5.05H46.93L20.81,95.21c-1.21,1.04-3.04,0.9-4.08-0.32c-0.51-0.6-0.74-1.34-0.69-2.07l1.39-20.07H17.2 c-4.73,0-9.03-1.93-12.15-5.05C1.93,64.59,0,60.29,0,55.56V17.2c0-4.73,1.93-9.03,5.05-12.15C8.16,1.93,12.46,0,17.2,0L17.2,0z M102.31,27.98c3.37,0.65,6.39,2.31,8.73,4.65c3.05,3.05,4.95,7.26,4.95,11.9v38.36c0,4.64-1.89,8.85-4.95,11.9 c-3.05,3.05-7.26,4.95-11.9,4.95h-0.61l1.42,20.44l0,0c0.04,0.64-0.15,1.3-0.6,1.82c-0.91,1.07-2.52,1.19-3.58,0.28l-26.22-23.2 H35.01l17.01-17.3h36.04c7.86,0,14.3-6.43,14.3-14.3V29.11C102.35,28.73,102.34,28.35,102.31,27.98L102.31,27.98z M25.68,43.68 c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9h30.35c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9H25.68L25.68,43.68z M25.68,29.32c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9H68.7c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9H25.68L25.68,29.32z M76.66,5.8H17.2c-3.13,0-5.98,1.28-8.05,3.35C7.08,11.22,5.8,14.06,5.8,17.2v38.36c0,3.13,1.28,5.98,3.35,8.05 c2.07,2.07,4.92,3.35,8.05,3.35h3.34v0.01l0.19,0.01c1.59,0.11,2.8,1.49,2.69,3.08l-1.13,16.26L43.83,67.8 c0.52-0.52,1.24-0.84,2.04-0.84h30.79c3.13,0,5.98-1.28,8.05-3.35c2.07-2.07,3.35-4.92,3.35-8.05V17.2c0-3.13-1.28-5.98-3.35-8.05 C82.65,7.08,79.8,5.8,76.66,5.8L76.66,5.8z"/></g></svg>
        </button>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://x.com/xound_io" target="_blank" style="margin-right: 15px;">
            <img loading="lazy" src="assets/social-x.svg" alt="X" />
          </a>
          <a href="https://www.facebook.com/xound.io" target="_blank" style="margin-right: 15px;">
            <img loading="lazy" src="assets/social-fb.svg" alt="Facebook" /> 
          </a>          
          <a href="https://linkedin.com/company/xound" target="_blank" style="margin-right: 15px;">
            <img loading="lazy" src="assets/social-linkedin.svg" alt="Linkedin" />          
          </a>
          <a href="https://discord.gg/fWRkNcb5nJd" target="_blank">
            <img loading="lazy" src="assets/social-discord.svg" alt="Discord" />          
          </a>
        </div>
        <div class="col">
          <a href="/roadmap" class="footer-link">Roadmap</a>
        </div>
        <div class="col">
          <a href="/privacy" class="footer-link">Privacy & Imprint</a>
        </div>
        <div class="col">
          <a href="/terms" class="footer-link">Terms and Services</a>
        </div>
        <!-- Additional columns as needed -->
      </div>
    </div>
  </footer>
  
</section>
